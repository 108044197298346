@import url(https://fonts.googleapis.com/css?family=Roboto);


/* :root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* background: #f1f1f1; */
}

.ant-table-cell{
  padding: 6px 6px !important;
  font-family: 'Roboto';
}

thead .ant-table-cell{
  text-transform: uppercase;
}

.mushak1-table-head thead tr td,.mushak1-table-head thead tr th{
  border: 1px solid #535151;
  color: #272727;
  font-weight: 600;
  text-align: center;
}

.mushak1-table-head tbody tr td{
  border: 1px solid #8e8c8c;
  color: #272727;
  font-weight: 600;
  text-align: center;
}


.mushak-3-pdf tr th{
  border: 1px solid #535151;
  color: #272727;
  font-weight: 600;
  text-align: center;
}
.mushak-3-pdf tr td{
  border: 1px solid #747171;
  color: #272727;
  padding: 2px;
  text-align: center;
}
.mushak3-bottom-header tr td{
  font-weight: 500;
}

.pdf-head p{
  font-weight: 500;
}

.sales-order-table th{
  text-align: start;
  border: 1px #bebdbd solid;
  padding: 10px;
  height: 20px;
}

.action-th,.action-td{
  border: none !important;
}

.table-input-filed td{
  text-align: start;
  border: 1px #bebdbd solid;
  padding: 5px !important;
  height: 20px;
}

.total-price-td{
  border-right: none !important;
}
.inventory-th{
  border-left: none !important;
}

.table-input-filed td input{
  border: none;
}

.table-input-filed .css-13cymwt-control {
  /* min-height: 38px; */
  border-color: none !important;
  border-radius: 5px !important;
  border-style: none !important;
  border-width: 0px !important;
  box-sizing: border-box;
  padding: 0px !important;
}


[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  appearance: none;
  background-color: #fff;
  border-color: #e3e3e3 !important;
  border-width: 1px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 0rem;
  --tw-shadow: 0 0 #c1c1c100;
}
